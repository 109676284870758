import { Button, Text } from "@chakra-ui/react";
import type { HeadFC, PageProps } from "gatsby";
import React from "react";

const IndexPage = ({ params }: PageProps) => {
  return (
    <div>
      <Text>{params.productId}</Text>
      <Button>ok</Button>
    </div>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Home Page</title>;
